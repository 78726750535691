import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import Img from "../images/popup/subscribe.jpg";
import Close from "../images/popup/close.png";
import emailjs from "emailjs-com";
import { useForm, Controller } from "react-hook-form";
import {
  useFirebaseApp,
  useUser,
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocDataOnce,
} from "reactfire";
import "firebase/auth";
import "firebase/firestore";

const ModalExample = (props) => {
  const {
    // buttonLabel,
    className,
  } = props;

  const firebase = useFirebaseApp();
  const firestore = useFirestore(firebase);

  const [formData, setFormData] = useState({
    email: "",
    fullname: "",
    contact: "",
    error: "",
  });
  const [modal, setModal] = useState(true);
  const [isSubscribed, setSubscribed] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);
  const [showDelayedModal, setShowDelayedModal] = useState(false);
  const [alreadySubscribed, setAlreadySubsribed] = useState(false);

  const toggle = () => setModal(!modal);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const subButtonClicked = (data, e) => {
    e.preventDefault();
    // if (validateForm()) {
    setBtnClicked(true);
    console.log("Data: " + JSON.stringify(data));

    firestore
      .collection("SubscribedUsers")
      .where("email", "==", data.email)
      .get()
      .then((snapshot) => {
        setSubscribed(true);
        if (snapshot.docs.length > 0) {
          // alert("You have already subsribed to the newsletters.");
          setAlreadySubsribed(true);
        } else {
          setSubscribed(true);

          firestore
            .collection("SubscribedUsers")
            .doc()
            .set({ email: data.email })
            .then(() => {
              var template_params = {
                name: data.fullname,
                userEmail: data.email,
                subject: "New Subscriber",
                message: `${data.fullname} just subscribed using email: ${data.email} and number: ${data.contact}`,
              };

              emailjs
                .send(
                  "service_1qnspxs",
                  "template_909sycj",
                  template_params,
                  "user_C9512qbCLCTy86RLPgN2z"
                )
                .then(
                  (result) => {
                    reset();
                  },
                  (error) => {
                    console.log(error.text);
                  }
                );
            });
        }
      });
    // } else {
    //   alert("Please fill valid details in the form");
    // }
  };

  const handleChange = (e) => {
    console.log("Changed: " + e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value, error: "" });
  };

  console.log("State Changed1: " + modal);
  console.log("State Changed2: " + isSubscribed);
  console.log("State Changed3: " + btnClicked);
  console.log("State Changed4: " + alreadySubscribed);

  function validateForm() {
    let formIsValid = true;

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (typeof formData.email !== "undefined") {
      //regular expression for email validation
      if (formData.email == "" || formData.email == undefined) {
        formIsValid = false;
        formData.error = "Please enter your email.";
      } else {
        if (!pattern.test(formData.email)) {
          formIsValid = false;
          formData.error = "Please enter valid email.";
        }
      }
    }

    if (formData.contact == "" || formData.contact == undefined) {
      formIsValid = false;
      formData.error = "Please enter your contact no.";
    }

    if (formData.fullname == "" || formData.fullname == undefined) {
      formIsValid = false;
      formData.error = "Please enter your full name";
    }

    return formIsValid;
  }

  const SubscriptionBox = () => {
    let emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      <div>
        {/* <Button color="danger" onLoad={toggle}>{buttonLabel}</Button> */}
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalBody>
            {/* <ModalHeader toggle={toggle}></ModalHeader> */}
            <Row>
              <Col md="4" className="popup_img">
                <div className="modal_img">
                  <img src={Img} alt="img" />
                </div>
              </Col>
              <Col md="8" className="popup_data">
                <a onClick={toggle} className="close_icon">
                  <img src={Close} alt="img" />
                </a>
                <div className="modal_text">
                  <h1 className="modal_text1">Welcome to Grey Space</h1>
                  <hr />
                  {
                    !isSubscribed ? (
                      <Form onSubmit={handleSubmit(subButtonClicked)} className="popup_mt">
                        <Row form>
                          <Col md={6} className="form_row">
                            <FormGroup>
                              {/* <Label >Full Name</Label> */}
                              <input
                                type="text"
                                className="popup_inpout"
                                name="fullname"
                                placeholder="Full Name"
                                {...register("fullname", {
                                  required: true,
                                })}
                              />
                              {errors.fullname && <p>Full name is required</p>}
                            </FormGroup>
                          </Col>
                          <Col md={6} className="form_row">
                            <FormGroup>
                              <input
                                type="phone"
                                className="popup_inpout"
                                name="contact"
                                placeholder="Contact No"
                                {...register("contact", {
                                  required: true,
                                })}
                              />
                              {errors.contact && <p>Contact is required</p>}
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <input
                            type="email"
                            className="popup_inpout"
                            name="email"
                            placeholder="Email"
                            {...register("email", {
                              required: true,
                            })}
                          />
                          {errors.fullname && <p>Email is required</p>}
                        </FormGroup>
                        <div className="explore_section_text">
                          <div className="button_center">
                            <Button type="submit" className="btn_mt" >SUBSCRIBE NOW</Button>
                          </div>
                        </div>
                      </Form>
                    ) : !alreadySubscribed ? (
                      <div>
                        <div className="sr_text">
                          <div className="modal_text">
                            <p>Thank you for subscribing to website.</p>
                            <br />

                            <div className="subscrib">
                              <div className="subscrib_canter">
                                <Button
                                  type="button"
                                  className="done"
                                  onClick={toggle}
                                >
                                  Done
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="sr_text">
                          <div className="modal_text">
                            <p>
                              You have already subsribed to the newsletters.
                            </p>
                            <br />

                            <div className="subscrib">
                              <div className="subscrib_canter">
                                <Button
                                  type="button"
                                  className="done"
                                  onClick={toggle}
                                >
                                  OK
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    // </div>
                  }
                </div>
              </Col>
            </Row>
          </ModalBody>
          {/* <ModalFooter>
      <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
      <Button color="secondary" onClick={toggle}>Cancel</Button>
    </ModalFooter> */}
        </Modal>
      </div>
    );
  };

  const SubscribedBox = () => {
    return (
      <div>
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalBody>
            <div className="modal_text">
              <h2>Thanks for subscribing.</h2>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };
  console.log("State: " + modal);

 
    if (
      window.localStorage.getItem("isSignedIn") == "true" 
    ) {
      firestore
        .collection("SubscribedUsers")
        .where("email", "==", firebase.auth().currentUser.email)
        .get()
        .then((snapshot) => {
          console.log("Snapshot: " + JSON.stringify(snapshot.docs.length));
          if (snapshot.docs.length > 0) {
            setModal(false);
          } else {
            setModal(true)
            // setBtnClicked(true);
          }
        });
  
      // console.log("Users: "+JSON.stringify(firebase.auth().currentUser.email));
    }
   
 

  useEffect(() => {
    setTimeout(() => {
      setShowDelayedModal(true);
    }, 12000);
  }, []);

  return showDelayedModal ? <SubscriptionBox /> : null;

  // if (isSubscribed) {
  //   return <SubscribedBox />;
  // } else {
  //   return <SubscriptionBox />;
  // }
};

export default ModalExample;
